/*
  To Add

  load stories from database
    ability to add them in the ui
    
  settings
    make the settings actually reflect the settings object
    ability to change it (saves locally with localStorage)
    stoiries in order setting
      saves the spot of the story so the user can return to where they left off

  show charts

  keep track of how many times a word has been seen and the correct ratio

  sentence length based on difficulty
    uses the number of times a word has been seen and the length of the word

*/

import { useState, useEffect, useRef } from 'react'

// Components
import DescriptionWindow from './Components/DescriptionWindow'
import HintWindow from './Components/HintWindow'
import SettingsWindow from './Components/SettingsWindow'
import Charts from './Components/Charts'

// Text
import podcast from "./Files/podcast.txt"
import wordFile from "./Files/1000.txt"
import { spanishWordObjects } from './Files/spanishWords'
import { spanishStories } from './Files/spanishStories'

// Sounds
import tapSound from "./Files/TapSound.mp3"
import bellSound from "./Files/BellSounds.mp3"
import keyboardSound from "./Files/KeyboardClickSound.mp3"

// Images
import gear from './Files/gearicon80px.png'

// Functions
// Css
import "./WordArrayGame.css"
import WordDisplay from './WordDisplay'
import WordDisplaySupabase from './WordDisplaySupabase'
import WordCard from './WordCard'
import SettingsWindow2 from './SettingsWindow2'
import WordsDisplay from './WordsDisplay'
import { cleanText } from '../../../../Global/Functions'
import { supabaseClient } from '../../../../Global/supabaseinit'
import EditStoriesWindow from './EditStoriesWindow'

export function dateStringUTC(){
  const currentdate = new Date()

  return currentdate.getFullYear() + "-"
  // The month is an index 0 - 11
  + (currentdate.getUTCMonth()).toString().padStart(2, '0') + "-"  
  + currentdate.getUTCDate().toString().padStart(2, '0') + "T"
  + currentdate.getUTCHours().toString().padStart(2, '0') + ":"  
  + currentdate.getUTCMinutes().toString().padStart(2, '0') + ":" 
  + currentdate.getUTCSeconds().toString().padStart(2, '0');
}
export function dateString(){
  const currentdate = new Date()

  return currentdate.getFullYear() + "-"
  // The month is an index 0 - 11
  + (currentdate.getUTCMonth()).toString().padStart(2, '0') + "-"  
  + currentdate.getUTCDate().toString().padStart(2, '0') + "T"
  + currentdate.getUTCHours().toString().padStart(2, '0') + ":"  
  + currentdate.getUTCMinutes().toString().padStart(2, '0') + ":" 
  + currentdate.getUTCSeconds().toString().padStart(2, '0');
}

/*

  1) useEffect setUpKeyPress is called 
      listens for spacebar which calls next

  2) when button is pressed start is called
      sets initial time and started flag
      calls startReading

  3) startReading
      creates an array and adds it to the top of the array of arrays with createArray
      sets the index to the top
      sets keyInput flag to false so the input is hidden and the words (first word because index is 0) are shown

  4) on spacebar push 
      next is called

  5) next
      if all words have shown calls startInput to start input mode
      else speaks the word
      and increments wordIndex so the next word is shown

  6) startInput
      setKeyInput(true) so the keyboard shows
      clears and focuses on the input bar

  7) input onChange: checkInputInprocess2
      checks the input words to the expected words
      plays sounds if there is a correct word or the whold array of words is correct
      if input is complete calls a position movement function: oneDepper, oneUp or toReadingMode

  8) toReadingMode
      sets movement flag and adds to itteration cound
      calls startReading (3)

*/

function WordArrayGame() {
    
  // ================================================================================
  // #region variable declarations

  // The 2d array of word arrays
  const [array, setArray] = useState([])  
  // The array with the words to choose from  
  const globalWordArray = useRef(["word"])
  const globalSentenceArray = useRef(["This is a sentence."])

  const pauseKeyListener = useRef()

  // Hint window
  const [hintCount, setHintCount ]= useState(3)
  const [showHint, setShowHint] = useState()
  // The current position in the current word array
  const [wordIndex, setWordIndex] = useState(0)
  // The current position in the 2d array
  const [arrayIndex, setArrayIndex] = useState(0)    

  // Dynamic settings
  const [arrayLength, setArrayLength] = useState(2)
  const [arrayDepth, setArrayDepth] = useState(4)
  const [speak, setSpeak] = useState(true)
  const [addWords, setAddWords] = useState(true)
  const [usePrevious, setUsePrevious] = useState(false)
  const [randomPrevious, setRandomPrevious] = useState(true)
  const [relivantWordsArray, setRelivantWordsArray] = useState([])
  const [relivantWordsDataObject, setRelivantWordsDataObject] = useState()
  const relivantWordsDataObjectRef = useRef()

  // Keep track of number in a row for changing difficulty
  const [correctStreak, setCorrectStreak] = useState(0)
  // Number of points earned in this game session
  const [points, setPoints] = useState(0)

  // The game mode
  const [started, setStarted] = useState(false)
  const [keyInput, setKeyInput] = useState(false)  
  
  // The display values
  const [correct, setCorrect] = useState({correct:0, total:0})
  const [time, setTime] = useState({start:"minutes:seconds", end:"minutes:seconds"})

  // Keeping track of things
  const [accuracyLog, setAccuracyLog] = useState(["Accuracy Log", "____________________"])
  const [itteration, setItteration] = useState(0)
  const [startSeconds, setStartSeconds] = useState(0)
  const [movingShallow,setmovingShallow] = useState(false)

  // Show or hide windows
  const [showChart, setShowChart] = useState()
  const [showSettings, setShowSettings] = useState()
  const [showDescription, setShowDescriptionWindow] = useState()  
  const [showWordsDisplay, setShowWordsDisplay] = useState()  
  const [showEditStoriesDisplay, setShowEditStoriesDisplay] = useState()  

  const defaultSettings = {
    maxWordArrayLength: 4,
    speak: true,
    speakTranslation: false,
    speakLetters: true,
    fromStories: true,
    fromLocalStories: true,
    fromSongs: false,
    fromWordsEs: false,
    fromWordsEn: false,
    fromNames: false,
    fromNumbers: false,
    fromDigits: false,
    fromLetters: false,
    confirmationNoise: true,
  }
  const [settings, setSettings] = useState(defaultSettings)  

  // Flag variable to prevent skiping a row of questions
  const dontGoDeeper = useRef(false)

  // ================================================================================
  // Word Lists

  // To add a wordlist: 
  // This File:     string, attribute on wordSourses state, createGlobalWordArray statement, 
  // Settings Page: input checkbox, ref, onChange function
  const [wordSources, setWordSources] = useState({
    miscWords: true,
    file1000: true,
    wordsUp: true,
    wordsDr: true,
    namesDr: false,
    names: false,
    numbers: false,
    digits: false,
    letters: false,
  })
  
  // Word Lists
  let miscWords = "time year people way day man thing woman life child world school family student hand part place palace week company system program question work number night point home water room mother area money story fact month lot right study book eye job word business side kind head house service friend father hour game line member car city community Name team minute idea kid body information parent others level office door health person art history party result change morning reason research girl guy moment air teacher education car value gold baby food plant blue sun moon cloud trees plants electricity computer keyboard mouse book page word symbol hair ability time house water council market city land sea lake ocean sand rocks animals crab goat deer alligator bull team town nature bank paper pen marker club king voice light music field forest mountain valley peak project base love letter capital model machine fire son space plan energy hotel parkingLot meet cup box summer village park garden science picture fish bird oil film addition station window door sound glass software earth fiver sale equipment radio peace teacher culture context weight sex transport cash library phone stone dog cat memory railroad train plane sky wood granite marble winter snow rain hill wind bank museum farm cabinet fridge coffee tea bridge connection air dinner lunch breakfast fruit cantaloupe watermelon potato bright clear happy reach up climb progress grow accept accomplish achieved active "
  // There is a file with 1000 commonn english words
  let wordsUp = "happy climb achieve bright sunny sun shining green travel water gold make create audit clean build meet talk joke laugh plants sky mountains trees rocks open space organize complete system "
  let wordsDr = "blue golden pink pale love pregnant together hold shirt pants dress skirt blanket bed pillow room partner help sit in into insert open egg positive surge disk round plastic fill belly wet drip liquid female woman girl muffin pie shower "
  let namesDr = "Natalie Whittney Tonya Savannah Briana Ashleigh Robbin Bailey Lexi Jodi Kate Melissa Gretchen Summer Pamela Caitlin Summerlyn Venita Tiff Shannon Valeria Kiara Davlin Nichole LeeAndra Sydney Jennifer Erin Ashlyn Kayla Loren Stephanie Jess Elizabeth Kaylee "
  let names = ""
  let letters = "atom bear cockroach dog elephant fire goat hose igloo journal kangaroo lizard monkey neon octopus pussyCat queen riot snake tea up vacuum walrus female male zygote "
  let numbers = "one two three four five six seven eight nine zero "
  let digits = "1 2 3 4 5 6 7 8 9 0 "  


  var infoString = 
  `
    Picture each word, then picture it performing an action on the next word creating a story. 
    The more rediculous the pictures and actions are the better.
    Take your time. Sometimes going fast is going slow.
    Grouping a few words into scenes and putting them into a grid can help. 
    Remembering the first scene of each grid and chaining it together with 
    the first scene of other grids can help you remember more.
    The F11 key will enter or exit full screen.
  `
  // #endregion variable declarations

  // ================================================================================
  // #region Setup
    useEffect(()=>{    
      let voices = window.speechSynthesis.getVoices()      
      loadPoints()
      loadArrays()
      loadSettings()
    }, [])  
    setUpKeyPress()

    useEffect(()=>{
      createGlobalWordArray() 
    }, [wordSources])
    useEffect(()=>{
      loadRelivantWordsData()
      
    }, [relivantWordsArray])

    useEffect(() => {
      if (!localStorage.getItem('currentStoryIndex')) {
        localStorage.setItem('currentStoryIndex', 0);
      }
      if (!localStorage.getItem('currentSectionIndex')) {
        localStorage.setItem('currentSectionIndex', 0);
      }
    }, []);

    async function loadRelivantWordsData(){

      let response = await supabaseClient
        .from("language_game_words")
        .select("*")
        .in("es", relivantWordsArray)

      // Convert it into an object and save it in state
      let tempWordsObject = {}
      if(response.data)
        response.data.forEach((wordData)=>{
          tempWordsObject[cleanText(wordData.es)] = wordData
        })

      setRelivantWordsDataObject(tempWordsObject)
      relivantWordsDataObjectRef.current = tempWordsObject
    }

    function loadSettings(){
      let loadedSettings = window.localStorage.getItem("language_game_settings")
      if(loadedSettings && typeof loadedSettings === "string")
        setSettings(JSON.parse(loadedSettings))
      else
        setSettings(defaultSettings)
    }

    async function createGlobalWordArray(){

      var words = ""

      // This one is exclusive, all the others are cumulative
      if(wordSources.podcast){
        await fetch(podcast)
        .then(res => res.text())
        .then(text => {        
          globalSentenceArray.current = stringToSentenceArray(text)
          return          
        })
      }



      if(wordSources.miscWords)
        words += miscWords
      if(wordSources.file1000)
        await fetch(wordFile)
        .then(res => res.text())
        .then(text => {
          words += text+" "
        })      
      if(wordSources.wordsUp)
          words += wordsUp      
      if(wordSources.wordsDr)        
          words += wordsDr      
      if(wordSources.namesDr)        
          words += namesDr
      if(wordSources.names)        
          words += names
      if(wordSources.numbers)        
          words += numbers
      if(wordSources.digits)        
          words += digits
      if(wordSources.letters)        
          words += letters

      // Convert the string to an array 
      globalWordArray.current = wordStringToWordArray(words)

    }

    const loadedLogRef = useRef({})
    const [logObject, setLogObject] = useState({})
    function loadPoints(){

      let loadedPoints = window.localStorage.getItem("Word-Array-Points")

      if(typeof loadedPoints === "string"){
        loadedPoints = JSON.parse(loadedPoints)
        loadedLogRef.current = loadedPoints
        loadedPoints = cleanObject(loadedPoints)
        console.log("loadedPoints: ", loadedPoints)
        setLogObject(loadedPoints)
      }
      else{
        loadedLogRef.current = {}
      }
      
    }
    function addPoints(words, depth, correctWords){
      
      let newPoints = (words * (depth + 1)) - (2 * (words - correctWords))
      let currentPoints = points + newPoints
      // Calculate the number of seconds the user has been playing
      let date = new Date()
      let seconds = (date.getTime() - startMSRef.current) / 1000    
  
      // Save the number of points in the db 

      // Get (or create) the log object for this session
      let updatedObject = loadedLogRef.current[startTimeRef.current]
      // If it doesn't yet exist create an empty object
      if(!updatedObject)
        updatedObject = {}
      


      // Update the values
      updatedObject.points = currentPoints
      updatedObject.seconds = seconds
      updatedObject.arrayLength = arrayLength


      loadedLogRef.current[startTimeRef.current] = updatedObject
    
      // Set state for display and next save
      setPoints(currentPoints)
  
      // Put the updated object in local storeage as a string
      window.localStorage.setItem("Word-Array-Points", JSON.stringify(loadedLogRef.current))
  
    }

    // This function is used to correct incorrect log entries
    function cleanObject(pointsObject){
      return pointsObject
      console.log("cleanObject pointsObject: ")
      console.log(pointsObject)

      let tempPointsObject = {}
      Object.entries(pointsObject).forEach(([date, data]) => {
        let tempObject = data
        if(tempObject?.arrayLength == 61)
          tempObject.arrayLength = 7
        tempPointsObject[date] = tempObject
      })

      console.log("cleaned points object")
      console.log(tempPointsObject)

      return tempPointsObject
    }


    function setUpKeyPress(){
      window.onkeydown=(e)=>{
        if(pauseKeyListener.current) return
        //console.log("pressed "+e.keyCode)
        switch(e.keyCode){        
          case 39:
            next()
            break
          case 32:
            next()
            break    
          case 68:
            //debug()
            break        
          case 83:
            spellWord()
            break        
        }
      }
    }

    // Used to keep track of the session name based on when the session was started YYYY-MM-DDTHH:MM:SS
    const startTimeRef = useRef()
    // When points are saved this is used as a reference to calculate play time for the session (miliseconds since Jan 1 1970)
    const startMSRef = useRef()
    function setInitialTime(){

      
      let date = new Date()      
      startTimeRef.current = dateString(date)   
      startMSRef.current = date.getTime()       

      // To parse it:
      //let newDate = new Date(startTimeRef.current)

    } 

  // #endregion Setup

  // ================================================================================
  // #region Array Generation

  const sentenceIndex = useRef(0)
  /**
    Called when it starts and also when going back to reading mode after input.

  */  
  async function createArray(){
        


    if(wordSources.podcast){

      var newWordsArray = globalSentenceArray.current[sentenceIndex.current].split(" ")
      addAtHead(newWordsArray)
      sentenceIndex.current++
      return
    }

    // The array that will hold the new array of words
    var newWordsArray = []
    // Add from old arrays
    if(usePrevious){
      // look in loaded arrays for an array that has a length that matches arrayLength 

      newWordsArray = loadArrayOfLength(arrayLength)
      if(!newWordsArray)
        newWordsArray = generateRandomWordArray(arrayLength)
    }
    if(settings.fromStories){
      newWordsArray = await generateArrayFromStory4(arrayLength) || [[]]
      console.log("newWordsArray: ", newWordsArray)
    }
    // Create a new array of words
    else{
      // console.log("generating new array")
      // Create a new array and put 10 random words in it
      newWordsArray = generateRandomWordArray(arrayLength)

    }

    if(speak)
      speakWord(newWordsArray[0])  

    // Adds the new word array at the head of the 2d array that holds word arrays
    addAtHead(newWordsArray)
  } 
  function loadArrayOfLength(length){
    let foundArray
    // console.log("loadArrayOfLength length: "+length+" loadedArrays:")
    // console.log(loadedArrays)
    if(loadedArrays && typeof loadedArrays === "object" && Object.entries(loadedArrays).length > 0){
      // console.log("loadArrayOfLength b")
      let randomSkip = Math.floor(Math.random() * Object.entries(loadedArrays).length)
      // console.log("randomSkip: " + randomSkip)
      let c = 0
      Object.entries(loadedArrays).forEach((([date, loadedArrayOfWordArrays]) => {

        // console.log("loadedArrayOfWordArrays")
        // console.log(loadedArrayOfWordArrays)

        // Skip a random number of dates
        if(randomPrevious){
          c++
          if(c < randomSkip) return
        }

        // If an array was found return
        if(foundArray) {
          // console.log("already found an array:")
          // console.log(foundArray)
          return
        }


  
        // Look at each array of words in that data object
        loadedArrayOfWordArrays.forEach(loadedWordArray => {
          // console.log("looking at word array: ")
          // console.log(loadedWordArray)

          // If the length matches what its looking for save it
          if(loadedWordArray.length == length){
            // console.log("found array with matching length:")
            // console.log(loadedWordArray)
            foundArray = [...loadedWordArray]
          }

        })

      }))
      
    }

    // console.log("foundArray")
    // console.log(foundArray)
    // If none was found this will be undefined
    return foundArray

  }
  // Loading new words TODO: try the database, if this doesn't work fall back to the local imported spanishWordObjects
  function generateRandomWordArray(length){

    // Finding <length> random words from the spanish words object
    let newWordsArray = []
    for(var i=0; i<length; i++){
      let newWord = spanishWordObjects[Math.floor(Math.random() * spanishWordObjects.length)]
      newWordsArray.push(newWord)
    }

    // This has all of the relivant words so they can be displayed in a window with translations
    setRelivantWordsArray(newWordsArray)  

    return newWordsArray    

    // let newWordsArray = []
    // for(var i=0; i<length; i++)
    //     newWordsArray.push(globalWordArray.current[Math.floor(Math.random() * globalWordArray.current.length)])
    // return newWordsArray

  }




  // TODO Choose a number of words from a story, move along in the story every time this function is called, for example first time it may take the first two words, then the next time it is called it will take the next 2
  // The index of the story we are looking at
  const currentStoryIndex = useRef();
  // The section of the story we are looking at 
  const currentStorySectionIndex = useRef(0)
  // The word position in the current story section
  const currentPosition = useRef(0);

  function generateArrayFromStory(length) {
    // Get current story's words as an array
    let currentStory = spanishStories[currentStoryIndex.current]?.content;
    currentStory = cleanText(currentStory);    
    const words = currentStory.split(' ');
  
    // Calculate how many words we can take from current position
    const remainingWords = words.length - currentPosition.current;
  
    // If not enough words remaining in current story
    if (remainingWords < length) {
      // console.log("remainingWords < length");
      // Take remaining words
      const result = words.slice(currentPosition.current);
  
      // Move to next story (loop back to first if at end)
      currentStoryIndex.current = (currentStoryIndex.current + 1) % spanishStories.length;
      currentPosition.current = 0;
  
      // Get remaining words needed from next story
      const remainingLength = length - remainingWords;
      const nextStoryWords = cleanText(spanishStories[currentStoryIndex.current].content).split(' ');
      return [...result, ...nextStoryWords.slice(0, remainingLength)];
    }
  
    // console.log(currentPosition.current, currentPosition.current + length);
  
    // Get words from current position
    const result = words.slice(currentPosition.current, currentPosition.current + length);
    // console.log("result", result);
  
    // Update position for next call
    currentPosition.current += length;
  
    // Reset if we reach end of story
    if (currentPosition.current >= words.length) {
      currentStoryIndex.current = (currentStoryIndex.current + 1) % spanishStories.length;
      currentPosition.current = 0;
    }
  
    return result;
  }

  // TODO load these from the data base, option to add new stories from UI, option to set story index and have then load in order
  function generateArrayFromStory2(length) {
    if(!currentStoryIndex.current)
      currentStoryIndex.current = Math.floor(Math.random() * spanishStories.length)

    // Get current story section's words as an array of words
    let currentStorySection = spanishStories[currentStoryIndex.current]?.contentSections[currentStorySectionIndex.current];     
  
    if(!currentStorySection || typeof currentStorySection !== "string"){
      console.error("no currentStorySection found")
      return
    }
    // This is to remove the things that make non words show as words
    currentStorySection = cleanText(currentStorySection);    
    const words = currentStorySection?.split(' ');
    setRelivantWordsArray(words) 

    // Calculate how many words we can take from current position
    const remainingWords = words.length - currentPosition.current;
  
    // If not enough words remaining in current story
    if (remainingWords < length) {
      // Take remaining words
      const result = words.slice(currentPosition.current);
  
      // Move to next story section
      currentStorySectionIndex.current = currentStorySectionIndex.current + 1
      // If that was the last section choose a new story
      if(currentStorySectionIndex.current >= spanishStories[currentStoryIndex.current].contentSections.length)
        chooseNewStory()
      
      // Return the array of words
      return [...result, ];
    }
  
    // Get words from current position
    const result = words.slice(currentPosition.current, currentPosition.current + length);

    // Update position for next call
    currentPosition.current += length;
  
    // Reset if we reach end of story choose a new story
    if (currentPosition.current >= words.length) 
      chooseNewStory()
  
    return result;
  }
  const [sectionString, setSectionString] = useState("")
  async function generateArrayFromStory3(length) {
    // Load the current story and section indices from localStorage
    let currentStoryIndex = parseInt(localStorage.getItem('currentStoryIndex')) || 0;
    let currentSectionIndex = parseInt(localStorage.getItem('currentSectionIndex')) || 0;
  
    // Load the next story from the database
    const { data: stories, error: storiesError } = await supabaseClient
      .from('language_game_stories')
      .select('*')
      .order('index', { ascending: true });
  
    if (storiesError) {
      console.error('Error loading stories:', storiesError);
      return;
    }
  
    // If no stories are found, return an empty array
    if (!stories || stories.length === 0) {
      return [];
    }
  
    // If the current story index is out of bounds, reset it to 0
    if (currentStoryIndex >= stories.length) {
      currentStoryIndex = 0;
      currentSectionIndex = 0;
    }
  
    const currentStory = stories[currentStoryIndex];
  
    // Load the next section from the database based on the story_id
    const { data: sections, error: sectionsError } = await supabaseClient
      .from('language_game_story_sections')
      .select('*')
      .eq('story_id', currentStory.id)
      .order('index', { ascending: true });

    // Find the section that has the next index after the current section index
    const nextSection = sections.find(section => section.index > currentSectionIndex);
    // If there is no next section, choose a new story
    if (!nextSection) {
      currentStoryIndex = (currentStoryIndex + 1) % stories.length;
      currentSectionIndex = 0;
      localStorage.setItem('currentStoryIndex', currentStoryIndex);
      localStorage.setItem('currentSectionIndex', currentSectionIndex);
      return generateArrayFromStory3(length); // Recursively call to get the next story
    }
    setSectionString(nextSection?.contentSections)

    if (sectionsError) {
      console.error('Error loading sections:', sectionsError);
      return;
    }
  
    // If no sections are found, return an empty array
    if (!sections || sections.length === 0) {
      return [];
    }
  
    // If the current section index is out of bounds, move to the next story
    if (currentSectionIndex >= sections.length) {
      currentStoryIndex = (currentStoryIndex + 1) % stories.length;
      currentSectionIndex = 0;
      localStorage.setItem('currentStoryIndex', currentStoryIndex);
      localStorage.setItem('currentSectionIndex', currentSectionIndex);
      return generateArrayFromStory3(length); // Recursively call to get the next story
    }
  
    const currentSection = sections[currentSectionIndex];
  
    // Update the words array to the content of the section
    const words = currentSection.content.split(' ');
    setRelivantWordsArray(words);
  
    // Update the current section index in localStorage
    localStorage.setItem('currentSectionIndex', currentSectionIndex + 1);
  
    return words.slice(0, length);
  }
  const currentWordPosition = useRef(1);
  const [storyTitle, setStoryTitle] = useState()
  const [sectionTitle, setSectionTitle] = useState()
  async function generateArrayFromStory4(resultLength) {

    // Convert the section string to an array
    let wordsArray = (sectionString || "")?.split(' ');    
    console.log("currentWordPosition.current, wordsArray: ", currentWordPosition.current, wordsArray.length, currentWordPosition.current < wordsArray.length)


    // If there are still words to be used
    if(currentWordPosition.current < wordsArray.length){
      // Get the remaining words based on the resultLength prop
      const result = getWordsFromString(sectionString, resultLength)
      
      console.log("still words to be used: ", result)

      // This is the array of words to be displayed
      return result

    }
    // If there are no remaining words, choose a new section and if necessary a new story
    else{
      
      currentWordPosition.current = 0;

      // Get the position index's from local storage (story index, and section index)
      let storyIndex = parseInt(localStorage.getItem('currentStoryIndex')) || 0;
      let sectionIndex = (parseInt(localStorage.getItem('currentSectionIndex')) || -1) + 1;
      localStorage.setItem('currentSectionIndex', sectionIndex)

      // Load the story
      let story = await loadStory(storyIndex);
      
      console.log("story: ", story)

      // Load the section and get the content string
      let sectionData = await loadSection(story.id, sectionIndex);
      let sectionString = cleanText(sectionData?.content)
      setRelivantWordsArray(sectionString?.split(" ") || [])


      console.log("sectionData: ", sectionData)

      // Get the remaining words based on the resultLength prop
      const result = getWordsFromString(sectionString, resultLength)
      
      console.log("getWordsFromString result: ", result)

      // Put that data in state
      setSectionString(sectionString)

      // This is the array of words to be displayed
      return result
  
    }      
  }

  async function loadStory(storyIndex) {
    // Load all stories from the database, ordered by index
    const { data: stories, error } = await supabaseClient
        .from('language_game_stories')
        .select('*')
        .order('index', { ascending: true });

    if (error) {
        console.error('Error loading stories:', error);
        return null;
    }

    if (stories.length === 0) {
        console.error('No stories found in the database.');
        return null;
    }

    // Find the story with the exact index
    let story = stories.find(s => s.index === storyIndex);

    // If no story with the exact index is found, find the next story with the smallest index greater than storyIndex
    if (!story) {
        story = stories.find(s => s.index > storyIndex);

        if (!story) {
            // If no next story is found, loop back to the first story
            story = stories[0];
            storyIndex = story.index; // Update the storyIndex to the first story's index
        } else {
            storyIndex = story.index; // Update the storyIndex to the next story's index
        }

        // Update localStorage with the new storyIndex
        localStorage.setItem('currentStoryIndex', storyIndex);
    }

    setStoryTitle(story?.title)

    return story;
  }
  async function loadSection(storyID, sectionIndex) {
    // Load all sections for the given storyID, ordered by index
    const { data: sections, error } = await supabaseClient
        .from('language_game_story_sections')
        .select('*')
        .eq('story_id', storyID)
        .order('index', { ascending: true });

    if (error) {
        console.error('Error loading sections:', error);
        return null;
    }

    if (sections.length === 0) {
        console.error('No sections found for the story:', storyID);
        return null;
    }

    // Find the section with the exact index
    let section = sections.find(s => s.index === sectionIndex);

    if (!section) {
        // If no section with the exact index is found, find the next section with the smallest index greater than sectionIndex
        section = sections.find(s => s.index > sectionIndex);

        if (!section) {
            // If no next section is found, move to the next story
            const nextStory = await loadStory(parseInt(localStorage.getItem('currentStoryIndex')) + 1);
            if (!nextStory) {
                console.error('No more stories available.');
                return null;
            }

            // Update localStorage with the new story and reset sectionIndex to 0
            localStorage.setItem('currentStoryIndex', nextStory.index);
            localStorage.setItem('currentSectionIndex', 0);

            // Recursively call loadSection with the new story's ID and sectionIndex 0
            return loadSection(nextStory.id, 0);
        } else {
            sectionIndex = section.index; // Update the sectionIndex to the next section's index
        }

        // Update localStorage with the new sectionIndex
        localStorage.setItem('currentSectionIndex', sectionIndex);
    }

    setSectionTitle(section?.title)

    return section;
  }
  function getWordsFromString(sectionString, resultLength = 4){
    if(!sectionString || typeof sectionString !== "string") return

    let wordsArray = (sectionString || "")?.split(' ');    

    // Get the remaining words based on the resultLength prop
    const result = wordsArray.slice(currentWordPosition.current, currentWordPosition.current + resultLength);   
    
    // Update the current word position
    currentWordPosition.current = currentWordPosition.current + resultLength;

    // This is the array of words to be displayed
    return result
  }
  function resetCounters(){
    localStorage.setItem('currentStoryIndex', 0)
    localStorage.setItem('currentSectionIndex', 0)
  }
  async function loadStories(){
    // Load all stories from the database
    const { data: stories, error: storiesError } = await supabaseClient
      .from('language_game_stories')
      .select('*')
      .order('index', { ascending: true });

    if (storiesError) 
      console.error('Error loading stories:', storiesError)

    return stories
  }
  async function loadNextStory(){

  }

  function loadNextSection(storyID, currentSectionID){
    // Attempt to load the next section
    // If there is no next section return null and it will choose a new story
  }

  function chooseNewStory(){
    // Choose another story
    currentStoryIndex.current = Math.floor(Math.random() * spanishStories.length)
    // Reset the counters
    currentStorySectionIndex.current = 0
    currentPosition.current = 0;
  }

  /**
   * Save the array in local storage so it can be used as a starting point later
   */ 
  function saveArray(array){
    // Get them from local storage on startup and put in a variable
    let tempLoadedArrays = {...loadedArrays}
    
    // Add the array to the object and put it in local storage
    tempLoadedArrays[startTimeRef.current] = array

    // console.log("saving arrays:")
    // console.log(tempLoadedArrays)

    window.localStorage.setItem("savedArrays", JSON.stringify(tempLoadedArrays))

  }

  // What is the purpose of this? maybe to be able to go through the same arrays again?
  const [loadedArrays, setLoadedArrays] = useState({})
  function loadArrays(){
    let loadedArraysTemp = window.localStorage.getItem("savedArrays")
    if(loadedArraysTemp)
      setLoadedArrays(JSON.parse(loadedArraysTemp))
    
  }

  function wordStringToWordArray(string){
    if(!string || typeof string !== "string") return

    // Remove new line characters
    let words = string.replaceAll('\n'," ") || []
    words = words.replaceAll('\r'," ")
    words = words.replaceAll(','," ")
    words = words.replaceAll('.'," ")
    // Remove redundant spaces
    for(let i=0; i<5; i++)
      words = words.replaceAll("  "," ")    
    // Split the string into an array
    var allWords = words.split(" ")

    // allWords.forEach((word, index) => {
    //   if(word.replaceAll(" ", "") === "")
    //     console.log("empty word at index "+index)
    // })

    // Filter out empty string arrays
    allWords = allWords.filter(word => word.replaceAll(" ", "") !== "")
    return allWords
  }
  function stringToSentenceArray(string){
    // remove unwanted substrings from the raw string
    let string2 = string.replaceAll(",", "")    
    string2 = string2.replaceAll("\r", "")
    
    // Split the string into lines
    let lines = string2.split("\n")
    // Filter out the time stamp lines
    lines = lines.filter(line => line[0] !== "[")
    
    // Split each line into sentences and push them to the sentences array
    let sentences = []
    lines.forEach(line => {
      line.split(".").forEach(sentence => {
        sentences.push(sentence)
      })
    })

    // Remove empty sentences
    sentences =  sentences.filter(line => line.replaceAll(" ", "") !== "")

    return sentences

  }

  // #endregion array generation

  // ================================================================================
  // #region Helper functions
  function addAtHead(wordArray){
    console.log("addAtHead", wordArray)
    
    // Create an array of the appropriate length and a counter
    var newArray = new Array(array.length+1)    
    var c = 0

    // Add the new word array to the beginning
    newArray[c++] = wordArray

    // Add all of the previous word arrays after it
    array.forEach(wordArray =>{
      newArray[c++] = wordArray
    })

    // Save the array in local storage for later retrevial
    saveArray(newArray)

    // Put it in the state variable
    setArray(newArray)
  }

  function speakWordFromIndex(index){
    if(!Array.isArray(array[arrayIndex]) || array[arrayIndex].length <= index) return

    // Get the word
    let word = array[arrayIndex][index]

    // Speak it
    speakWord(word)
  }


  // Speak the word object or word string
  async function speakWord(word){

    // If it is just a string speak it just in one language
    if(typeof word === "string"){
      speakWordEs(word)
      setTimeout(() => {
        spellWord(word)        
      }, 250);
      setTimeout(() => {   
        if(relivantWordsDataObject){
          speakWordEn(relivantWordsDataObjectRef.current[cleanText(word)]?.en)
        }   
      }, 500);
    }
    // TODO this should be doing what it does based on the settings 
    else{     
      speakWordEs(word?.es)      
      setTimeout(() => {
        spellWord(word)        
      }, 250);
      setTimeout(() => {        
        speakWordEn(word?.en)
      }, 500);
    }    
  }
  async function speakWordEn(word){
    if(!word || typeof word !== "string") return

    let utteranceEn = new SpeechSynthesisUtterance(word)
  
    // Set the voice to english
    let voice = speechSynthesis.getVoices().find(voice => voice.lang === "en-US")
    if(voice)
      utteranceEn.voice = voice

    // Speak it
    let synth = window.speechSynthesis
    synth.speak(utteranceEn)
  }
  async function speakWordEs(word){
    let utteranceEs = new SpeechSynthesisUtterance(word)
  
    // Set the voice to spanish    
    let voices = window.speechSynthesis.getVoices()      
    // console.log("found voices:", voices)    
    let voice 
    voice = await voices.find(voice => voice.name?.includes("Sabina"))
    if(!voice)
      voice = await voices.find(voice => voice.lang?.includes("es"))
    if(voice)
      utteranceEs.voice = voice
    
    // console.log("voice es: ", voice)    

    // Speak it
    let synth = window.speechSynthesis
    synth.speak(utteranceEs)

  }

  function displayMessage(message){
    document.getElementById("messageDisplay").innerHTML = message
  }
  function getSeconds(){
    var current = new Date()    
    var seconds = current.getSeconds()
    var minutes = current.getMinutes()
    var hours = current.getHours()
    var totalSeconds = (seconds)+(minutes*60)+(hours*3600)
    return totalSeconds
  }
  function showHintFunction(){    

    if(hintCount>0)
      setHintCount(hintCount - 1)

    setShowHint(true)
  }

  const audioRef = useRef()
  function playSound(sound){
    if(!sound) return

    if(!audioRef.current){
      try{
        audioRef.current = new Audio(sound)
      }catch{}
    }
    else
      // If its currently playing try again in a few milliseconds
      if(!audioRef.current.paused){
        setTimeout(() => {
          playSound(sound)
          
        }, 100);
        return
      }
      // If its paused set the new sound
      else
        audioRef.current.setAttribute("src", sound)

    try{
      audioRef.current.play()            
    }catch{}
    
  }

  function spellWord(word){
    if(!word || typeof word !== "string") return
    let letters = word.split("")    
    letters.forEach(letter => {
      speakWordEn(letter)
    })
  }
 
  // #endregion Helper Functions

  // ================================================================================
  // #region Accuracy Checking: checkInputInprocess2, addPoints, correctStreakAdjuster

  function checkInputInprocess2(input, array){
    // console.log("checkInputInprocess2")

    // Create an array from the input words and compare it to the state array
    let totalWordsInArray = array[arrayIndex].length
    // The words that have been typed into the input box
    let inputWordsArray = input.split(' ')    
    // Keeping track of the word it is looking at and the number of correct words
    let wordIndex = 0, correctCount = 0
    let correctWordsArray = array[arrayIndex]

    // TODO this is meant to handle words with spaces in them
    // this contains an array of strings, the string may have a space in it, 
    // generate an array of strings that has the string or multiple strings if there is a space
    // array[arrayIndex].forEach(word => {
    //   correctWordsArray = []
    //   if(typeof word === "string" && word.includes(" ")){
    //     let words = word.split(" ")
    //     words.forEach(word => {
    //       correctWordsArray.push(word)
    //     })
    //   }
    //   else
    //     correctWordsArray.push(word)
    // })

    inputWordsArray.forEach(word => {

      let inputWord = word
      // If there is a input word make it all lowercase for comparison
      if(inputWord && typeof(inputWord) === "string"){        
        inputWord = inputWord.toLowerCase()
        inputWord = normalizeSpanishText(inputWord)
      }
      
      // The array word may be a string or an object 
      let arrayWord = correctWordsArray[wordIndex]
      if(arrayWord?.es)
        arrayWord = arrayWord?.es
      // If there is an array word make it all lowercase for comparison
      if(arrayWord && typeof(arrayWord) === "string"){
        arrayWord = arrayWord.toLowerCase()
        arrayWord = normalizeSpanishText(arrayWord)
      }
      
      // Compare them
      if(inputWord === arrayWord)
        correctCount++
      
      // Look at the next word
      wordIndex++
           
    })
    
    // Check to see if there is an additional correct word, if so play the noise
    if(correctCount > correct.correct)
      if(correctCount === totalWordsInArray)
        playSound(bellSound)
      else
        playSound(tapSound)

    // Display the current number of correctly entered words and total entered words every time a letter is input
    setCorrect({correct:correctCount, total:totalWordsInArray})    

    // When we get to the furthest depth set a flag variable that says were on our way back
    //  then start decrementing counter. If flag is set and we get back to top, create new array and reset flag
    // If the number of words input is greater than the number of words in the array, input is complete
    if(inputWordsArray.length > totalWordsInArray){

      addPoints(totalWordsInArray, arrayIndex, correctCount)

      // If the number of correct words equals the number of words in the array it adds to the streak and possibly increaces the difficulty
      if(correctCount == totalWordsInArray)
          correctStreakAdjuster(true)
      else
          correctStreakAdjuster(false)

      // If (still moving deeper) and (next depth is within bounds of array and the max depth setting) we want to ask for the next deeper
      if(!movingShallow && (arrayIndex+1 < array.length) && (arrayIndex+1 < arrayDepth))
        // Sets the depth one deeper and starts input
        oneDeeper()
      // Else sart going more shallow
      else{
        
        // set flag
        setmovingShallow(true)

        // If back at start, make a new array and start reading
        if(arrayIndex<=0){
            toReadingMode()
        }
        // Else read one more shallow
        else
          oneUp()
      }                  
    }            
  }

  function normalizeSpanishText(text) {
    // Replace special characters with their non-accented equivalents
    return text
        .replace(/á/g, 'a')
        .replace(/é/g, 'e')
        .replace(/í/g, 'i')
        .replace(/ó/g, 'o')
        .replace(/ú/g, 'u')
        .replace(/ñ/g, 'n')
        .replace(/ü/g, 'u')
        .replace(/¿/g, '') // Remove inverted question mark
        .replace(/¡/g, ''); // Remove inverted exclamation mark
  };
  
  function correctStreakAdjuster(correct){
    // If correct
    if(correct){
        // If the streak plus one is more than the array length then we will add to the array length (idk why this is the way to decide when to add)
        if(correctStreak + 1 >= arrayLength){
          
          // If it is still within the max word array length add to the array length (the number of words that will be in the sentence)
          if(arrayLength + 1 <= settings.maxWordArrayLength){

            // Update it
            setArrayLength(arrayLength + 1)
            
            // Reset the correct streak
            setCorrectStreak(0)
          }


        }
        // Just adding to the correct streak
        else{
            setCorrectStreak(correctStreak + 1) 
        }
    }
    // If not correct reset the streak
    else{
        setCorrectStreak(0)
    }
  }

  // #endregion 

  // ================================================================================
  // #region Game Position Movement

  function start(){
    setStarted(true)    
    startReading()
    setInitialTime()   
    setStartSeconds(getSeconds())  
  }
  function next(){
    
    // If the game is not started or user is typing return
    if(!started || keyInput)
      return

    // Cancel any ongoing speech synthesis
    window.speechSynthesis.cancel()

    // If all words have shown start input mode
    if(wordIndex + 1 == array[arrayIndex].length){
      // console.log("all words shown")
      startInput(0)    
    }
    // If not all of the words have been shown and speak is set to on speak the next word
    else if(speak){
      speakWordFromIndex(wordIndex + 1)
    }
    
    // Increment the wordIndex to see the next word
    setWordIndex(wordIndex + 1)   

  }
  function inputChange(){        
    
    // Only check if the game has been started and user is typing
    if(!started || !keyInput)
      return    

    // Get the input string
    var input = ""
    if(document.getElementById("inputField"))
      input = document.getElementById("inputField").value
    else
      console.error("error finding input field")

    // If there is a space at the beginning ignore it
    if(input.charAt(0) === ' ')
      input = input.slice(1, input.length)    

    if(input === "")
      return
    if(array.length === 0)
      return

    // Check the input to see if it is complete and display number correct
    checkInputInprocess2(input, array)

  }

  function oneDeeper(){
    // console.log("oneDeeper")
    if(dontGoDeeper.current) return

    // Add the current to the log          
    var tempAL = accuracyLog
    tempAL.push("input "+correct.correct+" of "+array[arrayIndex].length+" correctly at depth "+arrayIndex)                                      
    setAccuracyLog(tempAL)

    // Doing this before the set state because it will probably call before state is updated if put after, but not always
    startInput(arrayIndex+1)

    // Go to the next depth level, then start input
    setArrayIndex(arrayIndex + 1)
  }

  function oneUp(){
    // console.log("oneUp")

    // Add the current to the log          
    var tempAL = accuracyLog
    tempAL.push("input "+correct.correct+" of "+array[arrayIndex].length+" correctly at depth "+arrayIndex)                                      
    setAccuracyLog(tempAL)
 
    // Doing this before the set state because it will probably call before state is updated if put after, but not always
    startInput(arrayIndex-1)

    // Go to the next depth level, then start input
    setArrayIndex(arrayIndex - 1)
  }
  function toReadingMode(){
    
    // Reset the flag variable for the next itteration
    setmovingShallow(false)

    // Update the itteration state (keeps track of how many cycles have been completed)
    setItteration(itteration+1)

    // Go to the next
    startReading()
  }

  function startReading(){    
    // Creates a new word array and adds it to the head of the array of word arrays
    if(addWords)
      createArray()
    
    // Look at the first word in the top word array
    setWordIndex(0)
    setArrayIndex(0)

    // Hide the input display
    setKeyInput(false)

    // Display a message so user knows what to do
    displayMessage("Memorize this list of "+arrayLength+" words. See the next word by pressing the space key or clicking below the word.")

    // When user presses spacebar next() will be called from setUpKeyPress
  }
  const inputRef = useRef()
  function startInput(depth){

    // This it to prevent the oneDeeper function from being called on start
    dontGoDeeper.current = true
    setTimeout(() => {
      dontGoDeeper.current = false
    }, 250);

    // Show the input field and hide the word display
    setKeyInput(true)

    playSound(keyboardSound)

    // It was not finding the input field without the timeout
    setTimeout(function() {
      let inputField = document.getElementById("inputField")
      if(!inputField){
        console.error("Input field error")
      }
      inputField.focus();
      inputField.value = "";
    }, 50);
 
    // Display a message so user knows what to do
    displayMessage("type the "+array[arrayIndex].length+" words in order from the list "+depth+" back")    

  }



  // #endregion Game Position Movement

  // ================================================================================
  // #region Dev

  function convertLogObject(logObject){
    let convertedLogObject = {}
    Object.entries(logObject).forEach(object => {
      let key = object[0]
      let values = object[1]
      convertedLogObject[key] = values
      if(convertedLogObject[key]?.seconds === convertedLogObject[key]?.points)
        convertedLogObject[key].seconds = (convertedLogObject[key]?.points * 10)

    })
    return convertedLogObject
  }

  function debug(){ 
    console.log("________________________________________")
    console.log("array")
    console.log(array)
    console.log("counter states:")
    console.log("counter "+wordIndex+" index wordIndex "+arrayIndex)
  }
  
  // #endregion

  return (
    <div className='appContainerOuter'>

      <div 
        className='appContainer'        
      >         

        {/* Word Display */}
        {(started && !keyInput) && 
          <div className=''>                        
            <WordDisplay wordData={relivantWordsDataObjectRef?.current[cleanText(array?.[arrayIndex]?.[wordIndex])] || array?.[arrayIndex]?.[wordIndex]} pauseKeyListener={pauseKeyListener} key={Math.random()}></WordDisplay>            
            <div className='nextClickBox' onClick={next}>
            </div>
            {/* <button onClick={getVoices}>Voices</button> */}
          </div>
        } 

        {/* Input Display */}
        <div className={(started && keyInput) ? " ":" hidden"}>
          <input id='inputField' ref={inputRef} placeholder='Type Here' className='wordArrayInputBox' autoComplete="off" onChange={inputChange}></input>
          <div>            
            <div className='currentDisplay'>
              {correct.correct + " of "+correct.total+" entered correctly & in order"}
            </div>

            {/* <div className='lastDisplay'>
              {correct.correct + " of "+correct.total+" entered correctly & in order"}
            </div>               */}
          </div>
        </div>

        {/* Message Display */}
        <div className='wordArrayMessageDisplay'>
          <div id='messageDisplay'>
            
          </div>
        </div>
        {!started && <div className='button buttonBig' onClick={start}>Start</div>}                                
        {false && <img src={require("./images/gearicon80px.png")} className='wordArrayMessageDisplay' style={{height:"20px", objectFit:"contain"}}></img>}

        {/* Buttons and indicators */}
        <div className='bottomRight'>
            <div className='inlineBlock' title={"Streak: "+correctStreak+". When the streak == the array length, the array length increments."}>
                {correctStreak}
            </div>
            <div className='inlineBlock' title={"Points: "+points+" = sum of Array length * depth - (2 * incorrect)"}>
                {points}
            </div>

        </div>
        
        {/* Windows */}
        {showHint && <HintWindow wordArrays={array} close={()=>setShowHint(false)} hintCount={hintCount}></HintWindow>}        
        {showChart && <Charts name={"Word Array Points"} logObject={logObject} close={()=>setShowChart(false)}></Charts>}
        {showSettings && 
          <SettingsWindow2 
            close={()=>setShowSettings(false)}
            defaultSettings={defaultSettings}
            loadSettingsCallback={loadSettings}          
          ></SettingsWindow2>
          // <SettingsWindow 
          //   close={()=>setShowSettings(false)}
          //   arrayLength={arrayLength}
          //   setArrayLength={setArrayLength}
          //   arrayDepth={arrayDepth}
          //   setArrayDepth={setArrayDepth}
          //   speak={speak}
          //   setSpeak={setSpeak}
          //   confirmationNoise={confirmationNoise}
          //   setConfirmationNoise={setConfirmationNoise}                    
          //   wordSources={wordSources}
          //   setWordSources={setWordSources}
          //   loadedArrays={loadedArrays}
          //   setAddWords={setAddWords}
          //   addWords={addWords}
          //   usePrevious={usePrevious}
          //   setUsePrevious={setUsePrevious}
          //   randomPrevious={randomPrevious}
          //   setRandomPrevious={setRandomPrevious}
          // ></SettingsWindow>
        }
        {showDescription && 
          <DescriptionWindow
            close={()=>setShowDescriptionWindow(false)}
          ></DescriptionWindow>
        }
        {showWordsDisplay && 
          <WordsDisplay
            close={()=>setShowWordsDisplay(false)}            
            words={relivantWordsArray}
            wordsObject={relivantWordsDataObject}
          ></WordsDisplay>
        }
        {showEditStoriesDisplay && 
          <EditStoriesWindow
            close={()=>setShowEditStoriesDisplay(false)}            
            words={relivantWordsArray}
            wordsObject={relivantWordsDataObject}
          ></EditStoriesWindow>
        }
        <div className='circleButtonHolder'>
          <div className='infoButton'>
            <img src={gear}></img>
            <div className='infoButtonDisplay'>
              <div className='settingsButton' onClick={()=>setShowChart(true)}>Charts</div>
              <div className='settingsButton' onClick={()=>setShowSettings(true)}>Settings</div>
              <div className='settingsButton' onClick={()=>setShowDescriptionWindow(true)}>Description</div>
              <div className='settingsButton' onClick={()=>setShowWordsDisplay(true)}>Words</div>
              <div className='settingsButton' onClick={()=>setShowEditStoriesDisplay(true)}>Stories</div>
              <div className='settingsButton' title={hintCount+" hints remaining"} onClick={showHintFunction}>Hint ({hintCount})</div>
              <div className='settingsButton' onClick={resetCounters}>Reset Counters</div>
              <div className='settingsButton'>{"story: " + storyTitle + " section: " + sectionTitle}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WordArrayGame;
