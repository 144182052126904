import React, { useState, useEffect } from 'react'
import "./WordCard.css"
import InputSupabase from '../../../../Components/DBInteractors/InputSupabase'
import { supabaseClient } from '../../../../Global/supabaseinit'
import { cleanText } from '../../../../Global/Functions'

export default function WordCard({wordData: wordDataParam, close = () => {}}) {

    const [editMode, setEditMode] = useState()    
    // Shows an extra field notes2
    const [expanded, setExpanded] = useState()    
    // Defaults to the param value, update by the input supabase on change
    const [wordData, setWordData] = useState(wordDataParam)    

    useEffect(() => {
        loadWordData()
    }, [wordDataParam])

    async function speakWordDataEs() {
        let utteranceEs = new SpeechSynthesisUtterance(wordData?.es)

        // Set the voice to spanish
        let voice 
        let voices = window.speechSynthesis.getVoices()      

        if(voices && voices.length > 0){
          voice = await voices.find(voice => voice.name?.includes("Sabina"))
          if(!voice)
            voice = await voices.find(voice => voice.lang?.includes("es"))        
          if(voice)
            utteranceEs.voice = voice
        }

        let synth = window.speechSynthesis
        synth.speak(utteranceEs)
    }

    async function loadWordData() {
        let wordToLookFor = cleanText(wordData?.es)
        let response = await supabaseClient
            .from("language_game_words")
            .select("*")
            .eq("es", wordToLookFor)                        

        if(response?.data && response?.data?.length > 0)
            setWordData(response?.data[0])
    }

    return (
        <div style={{top: expanded ? "10px" : ""}} className='wordCard' >

            {/* close button, top right corner */}
            <div className='closeButton wordCardCloseButton' onClick={close}>X</div>                
                    
            {/* Inner section of the card */}
            <div className='wordCardInner'>

                {/* Speak button */}
                <div className='speakButton' onClick={speakWordDataEs}>🔊</div>                
                
                {/* Spanish word */}
                <div className='wordCardTop'>
                    {wordData?.es}
                </div>

                {/* Image */}
                {/* <img src={wordData.image_url} className='wordCardImage'></img> */}

                {/* Image */}
                <InputSupabase                                 
                    defaultValue={wordData?.image_url}
                    table="language_game_words"
                    type="image"
                    rowId={wordData?.id}
                    otherUpdateValues={wordData}
                    attribute="image_url"
                    updateCallback={setWordData}
                    readOnly={!editMode}                    
                    style={{
                        height: "calc(100% - 190px)", 
                        width: "100%",
                    }}
                    
                />

                {/* English translation and edit button */}
                <div className='wordCardBottom'>
                    <div className='wordCardBottomSection' style={{borderBottom: "1px solid black", borderTop: "1px solid black", height: "35px", fontWeight: "bold", position: "relative"}}>
                        
                        {/* English translation */}
                        <InputSupabase                                 
                            defaultValue={wordData?.en}
                            table="language_game_words"
                            rowId={wordData?.id}
                            attribute="en"
                            updateCallback={setWordData}
                            readOnly={!editMode}
                            otherUpdateValues={wordData}
                            style={{
                                background: editMode ? "rgba(255,255,255,0.5)" : "transparent",
                                height: "100%",                                
                                width: "100%",                                
                                borderRadius: "0px",
                                textAlign: "center",
                            }}
                        />
                        
                        {/* Edit button */}
                        <div 
                            className='editButton' 
                            onClick={() => {
                                setEditMode(!editMode)
                            }}
                            title={editMode ? "Done" : "Edit"}
                            >
                            {editMode ? '✔️' : '✏️'}
                        </div> 
                        {/* Expand button */}
                        <div 
                            className='editButton' 
                            style={{right: "2px", left: "auto"}}
                            onClick={() => {
                                setExpanded(!expanded)
                            }}
                            title={expanded ? "Expand" : "Edit"}
                            >
                            {expanded ? '📖' : '📖'}
                        </div>                 

                    </div>
                    
                    {/* Note */}
                    <div className='wordCardBottomSection' style={{height: "120px"}}>                                            
                        <InputSupabase                                 
                            type="textarea"
                            table="language_game_words"
                            rowId={wordData?.id}
                            attribute="note"
                            updateCallback={setWordData}
                            placeholder="Note"
                            defaultValue={wordData?.note}
                            readOnly={!editMode}
                            otherUpdateValues={wordData}
                            style={{
                                background: editMode ? "rgba(255,255,255,0.5)" : "transparent",
                                height: "100%",                                    
                                minWidth: "100%",
                                maxWidth: "100%",
                            }}
                        />                                                           
                    </div>
                    {expanded && 
                        <InputSupabase                                 
                            type="textarea"
                            table="language_game_words"
                            rowId={wordData?.id}
                            attribute="note2"
                            updateCallback={setWordData}
                            placeholder="Note 2"
                            defaultValue={wordData?.note2}
                            readOnly={!editMode}
                            otherUpdateValues={wordData}
                            style={{
                                background: editMode ? "rgba(255,255,255,0.5)" : "transparent",
                                height: "100%",                                    
                                minWidth: "100%",
                                maxWidth: "100%",
                            }}
                        />
                    }                                                           

                </div>
            </div>
        </div>
    )
}