// import erikBanner from "./Images/erikBanner.jpg"
import bannerErik from "../Images/college-business-Erik.webp"
import bannerBlackDress from "../Images/bannerBlackDress.webp"
// import bannerBlueShirt from "./Images/bannerBlueShirt.webp"
import bannerFlowerEarings from "../Images/bannerFlowerEarings.webp"
import bannerGlasses from "../Images/bannerGlasses.webp"
import bannerGreyShirt from "../Images/bannerGreyShirt.webp"
import bannerOrangeHair from "../Images/bannerOrangeHair.webp"
import bannerPurpleHair from "../Images/bannerPurpleHair.webp"
import bannerPurpleShirt from "../Images/bannerPurpleShirt.webp"
// import bannerRedNecklace from "./Images/bannerRedNecklace.webp"

export function programBannerURL(){
    let bannerArray = [bannerErik, bannerBlackDress, bannerFlowerEarings, bannerGlasses, bannerGreyShirt, bannerOrangeHair, bannerPurpleHair, bannerPurpleShirt]
    let index = Math.floor(bannerArray.length * Math.random())
    return bannerArray[index]
}
export function scrollTop(customElementSelector){
    let elementSeelctor = "html"
    if(customElementSelector && typeof customElementSelector === "string")
    elementSeelctor = customElementSelector
    let htmlElement = document.querySelector(elementSeelctor)
    if(!htmlElement) return
    htmlElement.scrollTo(0, 0)
}
export function stringIsANumber(string){
    // If its not a string and already a number return true
    if(isANumber(string))
        return true
    // If it can be parsed into number return that number (will evalutate to true unless 0)
    if(!Number.isNaN(Number.parseInt(string)))
        return Number.parseInt(string) || true
    // If it is not a number and can't be parsed into a number return false
    return false
}
export function isANumber(number){
    if(typeof number === "number")
        return true
    return false
}
export function numberToCostPounds(number){
    return "£" + dotNumber(number)
}
export function dotNumber(number){
    return Number(number).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}
export function commaNumber(number){
    return Number(number).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
export function dateStringUTC(){
    const currentdate = new Date()

    return currentdate.getFullYear() + "-"
    // The month is an index 0 - 11
    + (currentdate.getUTCMonth()).toString().padStart(2, '0') + "-"  
    + currentdate.getUTCDate().toString().padStart(2, '0') + "T"
    + currentdate.getUTCHours().toString().padStart(2, '0') + ":"  
    + currentdate.getUTCMinutes().toString().padStart(2, '0') + ":" 
    + currentdate.getUTCSeconds().toString().padStart(2, '0');
}
export function cleanText(text) { 
    if(!text || typeof text !== "string") return 
    
    return text
        .toLowerCase() // Convert to lowercase for case-insensitive matching
        .replaceAll("\n", ' ') // Replace newlines with spaces
        .replaceAll("\s+", ' ') // Replace newlines with spaces
        // .replaceAll(/\n/g, ' ') // Replace newlines with spaces
        .replaceAll(/\s+/g, ' ') // Replace multiple spaces with a single space
        .replaceAll(",", ' ') // Replace multiple spaces with a single space
        .replaceAll(".", ' ') // Replace multiple spaces with a single space
        .replaceAll("  ", ' ') // Replace multiple spaces with a single space
        .replaceAll("  ", ' ') // Replace multiple spaces with a single space
        .trim(); // Remove leading/trailing spaces
    }