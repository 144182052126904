import React, { useState } from 'react'
import InputSupabase from '../../../../Components/DBInteractors/InputSupabase'
import { supabaseClient } from '../../../../Global/supabaseinit'

export default function EditStorySection({data}) {
    const [open, setOpen] = useState(false)
  
    async function deleteSection() {
        await supabaseClient
            .from("language_game_story_sections")
            .delete()
            .eq('id', data?.id)
    }

    return (
        <div className='storySection' >            
            
            {/* Title and open toggle */}
            <div className='storyTitle'>                
                <div className='closeButton' onClick={()=>setOpen(!open)}>{open ? '-' : '+'}</div>
                <InputSupabase 
                    defaultValue={data?.title || "New Section"}
                    table={"language_game_story_sections"}
                    rowId={data?.id}
                    attribute={"title"}
                ></InputSupabase>                                    
            </div>

            {/* Content */}
            {open &&
                <div>
                    <InputSupabase
                        type='textarea'
                        defaultValue={data?.content}
                        placeholder={"Story section content here:"}
                        table={"language_game_story_sections"}
                        rowId={data?.id}
                        attribute={"content"}
                        style={{height: "100px"}}
                    ></InputSupabase>
                    <div>
                        <button onClick={deleteSection}>Delete Section</button>
                    </div>
                </div> 
            }
        </div>
    )
}