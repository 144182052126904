import React, { useEffect, useState } from 'react'
import EditStorySection from './EditStorySection'
import InputSupabase from '../../../../Components/DBInteractors/InputSupabase'
import { supabaseClient } from '../../../../Global/supabaseinit'

export default function EditStory({data, stories, doneWithNew}) {
  
    console.log("EditStory data: ",data)

    const [open, setOpen] = useState(data?.new)
    const [sections, setSections] = useState()

    useEffect(() => {
        loadSections()
        const subscription = subscribeToSections()
        return () => {
            subscription.then(sub => sub.unsubscribe())
        }
    }, [data?.id])

    async function loadSections(){
        const response = await supabaseClient
            .from("language_game_story_sections")
            .select("*")
            .eq("story_id", data?.id)
        
        if(response.data)
            setSections(response.data)
        if(response.error)
            console.error("loadSections error ",response.error)
    }
    async function subscribeToSections(){      
                    
        console.log("subscribeToSections")

        let response = await supabaseClient
            .channel("sections-for-story-"+data?.id)
            .on("postgres_changes", {
                    event: "*",
                    schema: "public", 
                    table: "language_game_story_sections",
                },
                payload => {
                    console.log("sections changed payload: ", payload)
                    // console.log("contacts changed payload: ", payload)
                    loadSections()
                }
            )
            .subscribe()
            
            if(response.error)
                console.error("subscribeToStories error ",response.error)
        
        return response
    }

    function findNewIndex(){
        let highestIndex = 0
        sections.forEach(section => {
            if(section.index > highestIndex)
                highestIndex = section.index
        })
        return highestIndex + 1
    }

    async function addSection(){
        let response = await supabaseClient.from("language_game_story_sections")
            .insert([{
                title: "new section",
                story_id: data?.id,
                index: findNewIndex(),
            }])
        
        console.log("newStory response", response)         


        if(response.error)
            console.error("newStory error ",response.error)  
    }

    async function moveStory(storyId, direction) {
        const story = stories.find(s => s.id === storyId)
        const newIndex = story.index + direction
        
        if (newIndex < 0 || newIndex >= stories.length) return

        const otherStory = stories.find(s => s.index === newIndex)
        
        await supabaseClient
            .from("language_game_stories")
            .update({ index: newIndex })
            .eq('id', story.id)

        await supabaseClient
            .from("language_game_stories")
            .update({ index: story.index })
            .eq('id', otherStory.id)
    }
    async function deleteStory() {
        try {
            // First delete all sections that belong to this story
            const { error: sectionsError } = await supabaseClient
                .from("language_game_story_sections")
                .delete()
                .eq('story_id', data?.id)

            if (sectionsError) {
                console.error('Error deleting sections:', sectionsError)
                return
            }

            // Then delete the story itself
            const { error: storyError } = await supabaseClient
                .from("language_game_stories")
                .delete()
                .eq('id', data?.id)

            if (storyError) {
                console.error('Error deleting story:', storyError)
            }
        } catch (error) {
            console.error('Error in deleteStory:', error)
        }
    }

    return (
        <div className='storyEditor' key={"story-"+data?.id}>
            
            {/* Title and open toggle */}
            <div className='storyTitle'>
                <div className='closeButton' onClick={()=>setOpen(!open)}>{open ? '-' : '+'}</div>
                <InputSupabase 
                    defaultValue={data?.title || "New Story"}
                    table={"language_game_stories"}
                    rowId={data?.id}
                    attribute={"title"}
                    style={{width: "calc(100% - 150px)", marginRight: "10px"}}
                ></InputSupabase>
                <InputSupabase 
                    defaultValue={data?.index || 0}
                    table={"language_game_stories"}
                    rowId={data?.id}
                    attribute={"index"}
                    style={{width: "50px"}}
                ></InputSupabase>
            </div>
 
            {/* Sections */}
            {open && 
                <div>
                    <div style={{fontWeight: "bold", marginBottom: "10px", fontSize: "20px", borderBottom: "1px solid black"}}>Sections</div>
                    {data?.id === "new" &&
                        <EditStorySection data={{id: "new"}}></EditStorySection>
                    }
                    {sections?.map(section => 
                        <EditStorySection data={section}></EditStorySection>
                    )}
                    <div>
                        <button onClick={addSection}>Add Section</button>
                        <button onClick={()=>deleteStory()}>Delte</button>
                        <button onClick={()=>moveStory()}>Update Index</button>
                        <button onClick={()=>loadSections()}>Reload</button>
                        {data.new && <button onClick={doneWithNew}>Done</button>}
                    </div>
                </div>
            }

        </div>
    )
}