import React, { useEffect, useState } from 'react'

import { supabaseClient } from '../../../../Global/supabaseinit'
import EditStory from './EditStory'
import "./EditStoriesWindow.css"

/*

    The puspose of this is to allow the user to view and edit all of the stories
    stories are made of sections
    the section loads in the wordarraygame component and is used to set words for the game

    this compoment:
    loads all stories in the db from the language_game_stories table
    and displays them

    Story display:
        will start collapsed
        on click it will open showing the sections in side

    Section Display:
        will start collapsed
        on click it will open showing the words in side
        they are shown in a InputSupabase component textarea which updates the database
        when this input is changed the data in the editstorywindow componentis updated so it is accurate

    The story will have a hover menu at the top right 
        there will be an option to 
            add a section to the story
            add another story after that story
            delete the story
            changes visibility (toggles visibility state in the db)
            change the index of the story

    DB structure
        language_game_stories
            title
            index (used to order the stories)
        language_game_story_sections
            title
            content
            index (used to order the sections in the story)

    Later
        When a user opens the window 10 stories will load
        they are loaded orderd by index
        when they click load more 10 more will load
        it is possible they will not have an index value

        there is a search bar at the top with a search buttonthat will load all of the stories that have the search term in the title



        Prompt:        

        tables:
        language_game_stories
            id    
            title
            index
        language_game_story_sections
            id    
            title
            content
            index
            story_id

            load the next story from the supabase database
            load the next section from the database based on the story_id section attribute
            set the words array to the content of the section

        keep track of the current story and current section the user is on
            use localStorage to store these variables
            if the user finishes the section:
                go on to the next section in the story
                if there are no more sections in the story go on to the next story            
                if there are no more stories loop back to the first story

        note:
            the story_id is a text variable in the sections table
        
        special directives:
            only update what is necessary for this functionality, do not edit anything else
            update the generate array from story 3 function and use it where the generate array 2 function is currently being used
            make sure the counters are declaired and loaded and used properly so whenthe user returns to the game their place is saved

*/

export default function EditStoriesWindow({close = ()=>{}}) {

    const [stories, setStories] = useState([])
    const [newStoryID, setNewStoryID] = useState(false)

    useEffect(()=>{
        loadStories()
        const subscription = subscribeToStories()
        return () => {
            subscription.then(sub => sub.unsubscribe())
        }
    },[])

    async function loadStories(){                
        let response = await supabaseClient.from("language_game_stories").select("*")
        
        console.log("loadStories response: ",response)

        if(response.data)
            setStories(response.data)
        if(response.error)
            console.error("loadStories error ",response.error)
        
    }

    async function subscribeToStories(){      
                    
        console.log("subscribeToStories")

        let response = await supabaseClient
            .channel("stories-channel")
            .on("postgres_changes", {
                    event: "*",
                    schema: "public", 
                    table: "language_game_stories",
                },
                payload => {
                    console.log("stories changed payload: ", payload)
                    loadStories()
                }
            )
            .subscribe()
            
        if(response.error)
            console.error("subscribeToStories error ",response.error)

        return response
    }
    // One more than the highest index in all of the stories
    function findNewIndex(){
        let highestIndex = 0
        stories.forEach(story => {
            if(story.index > highestIndex)
                highestIndex = story.index
        })
        return highestIndex + 1
    }
    async function newStory(){       
        let response = await supabaseClient.from("language_game_stories")
            .insert([{
                title: "new story",
                index: findNewIndex(),
            }])
            .select()
            .single()

        setNewStoryID(response.data?.id)
        
        // console.log("newStory response", response)         

        if(response.error)
            console.error("newStory error ",response.error)   
    }
    // Given an array of objects with an "index" value sort by that value (there may be duplicate or missing index values, all items must be in the result)
    function sortByIndex(array) {
        // Use the sort method to sort the array by the "index" property
        return array.sort((a, b) => a.index - b.index);
    }
    return (
        <div className='window' style={{width: "95%", margin: "0 auto", left: "2.5%"}}>
            <div className='closeButton' onClick={close}>X</div>
            <div style={{fontWeight: "bold", margin: "5px", fontSize: "20px"}}>Stories Editor</div>
            <div style={{padding: "10px"}}>
                <button onClick={newStory}>New Story</button>     
                <button onClick={loadStories}>Reload</button>     
            </div>
            <div className='windowInner' style={{height: "calc(100% - 80px)", overflowY: "auto", paddingBottom: "40px"}}>
                {newStoryID && 
                    <div>
                        <EditStory data={{id: newStoryID, new: "true"}} doneWithNew={()=>setNewStoryID()}></EditStory>
                    </div>
                }
                {sortByIndex(stories).map(data => (
                    <EditStory data={data} stories={stories}></EditStory>
                ))}
            </div>   
        </div>
    )
}