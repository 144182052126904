import React from 'react'

import WordDisplay from './WordDisplay'
import { cleanText } from '../../../../Global/Functions'

export default function WordsDisplay({words = [], wordsObject, close}) {

  console.log("WordsDisplay", words, wordsObject)

  return (
    <div className='window' style={{width: "95%", margin: "0 auto", left: "2.5%"}}>
        <div className='closeButton' onClick={close}>X</div>
        <div className='windowInner' style={{height: "100%", overflowY: "auto"}}>
            {words.map(word => (
                <WordDisplay wordData={wordsObject[cleanText(word)] || {es: word}}></WordDisplay>
            ))}
        </div>        
    </div>
  )
}